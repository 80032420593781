@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Bold'), local('ClashDisplay-Bold'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Bold.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Extralight'), local('ClashDisplay-Extralight'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Extralight.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Regular'), local('ClashDisplay-Regular'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Regular.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Medium'), local('ClashDisplay-Medium'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Medium.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Light'), local('ClashDisplay-Light'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Light.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clash Display';
  src: local('Clash Display Semibold'), local('ClashDisplay-Semibold'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Semibold.woff2') format('woff2'),
      url('./assets/fonts/ClashDisplay/ClashDisplay-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
