/* Animations */
.intro-y {
  z-index: calc(50 - 50);
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  -webkit-animation: 0.4s intro-y-animation ease-in-out 0.33333s;
          animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: calc(1 * 0.1s);
          animation-delay: calc(1 * 0.1s);
}

.intro-x {
  z-index: calc(50 - 50);
  opacity: 0;
  position: relative;
  transform: translateX(50px);
  -webkit-animation: 0.4s intro-x-animation ease-in-out 0.33333s;
          animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: calc(1 * 0.1s);
          animation-delay: calc(1 * 0.1s);
}

.-intro-y {
  z-index: 2;
  opacity: 0;
  position: relative;
  transform: translateY(-50px);
  animation: 0.4s intro-y-animation ease-in-out 0.33333s;
  animation-fill-mode: forwards;
  animation-delay: calc(10 * 0.1s);
}

.-intro-x {
  z-index: 2;
  opacity: 0;
  position: relative;
  transform: translateX(-50px);
  animation: 0.4s intro-x-animation ease-in-out 0.33333s;
  animation-fill-mode: forwards;
  animation-delay: calc(10 * 0.1s);
}

.bounce {
  animation:bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0%{transform: translateX(-2px);}
  50%{transform: translateX(2px);}
  100%{transform: translateX(-2px);}
}

@-webkit-keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes intro-x-animation {
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@-webkit-keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes intro-y-animation {
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
